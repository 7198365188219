import React from 'react';
import { useLocation } from 'react-router-dom';

import { Perk } from 'components/Perk';

import styles from './styles.module.scss';

import { currencies } from 'utils/constants';
import { Routes } from 'global/Routes';
import { pathLocationCheck } from 'utils/shortCodeOrganisation';

export const Perks = ({ data, loading, error }) => {
  const location = useLocation();
  const firstPerkValuePersonalAndMedia = '500';
  const firstPerkValueEvent = '300';
  const firstPerkValueTimpris = 'För dig som vill styra ditt elpris';

  function scrollTo() {
    var element = document.getElementById('feesAndTaxes');
    element.scrollIntoView({ behavior: 'smooth' });
  }
  //const firstCircleValue =
  /*pathLocationCheck(location.pathname) === Routes.EVENT
      ? firstPerkValueEvent 
      : firstPerkValuePersonalAndMedia;*/

  const firstCircleValue = () => {
    if (pathLocationCheck(location.pathname) === Routes.EVENT) {
      return firstPerkValueEvent + ' ' + currencies.kr;
    } else if (pathLocationCheck(location.pathname) === Routes.TIMPRIS) {
      return firstPerkValueTimpris;
    } else return firstPerkValuePersonalAndMedia + ' ' + currencies.kr;
  };

  const firstCircleText = () => {
    if (pathLocationCheck(location.pathname) === Routes.TIMPRIS) {
      return '';
    } else return 'att handla för NU!';
  };
  return (
    <div className={styles.infoPerks}>
      {pathLocationCheck(location.pathname) !== Routes.PCE && (
        <Perk
          //value={`${firstCircleValue()} ${currencies.kr}`}
          //text="att handla för NU!"
          value={firstCircleValue()}
          text={firstCircleText()}
        />
      )}
      <div onClick={() => scrollTo()} className={styles.clickblePerk}>
        <Perk
          value={data.price_with_discount}
          text={currencies.unitPrice}
          light
          circle
          loading={loading}
          error={error}
        />
      </div>
      <div onClick={() => scrollTo()} className={styles.clickblePerk}>
        <Perk
          value={
            pathLocationCheck(location.pathname) === Routes.PCE ? (
              <>
                <span style={{ color: 'red' }}>0*</span>
                <span
                  style={{
                    color: 'white',
                    marginLeft: '4px',
                    position: 'relative',
                    display: 'inline-block',
                  }}
                >
                  59
                  <span
                    style={{
                      content: '""',
                      position: 'absolute',
                      left: '-10%',
                      top: 'calc(50% + -1px)',
                      width: '120%',
                      borderTop: '2px solid white',
                      transform: 'rotate(-45deg)',
                      height: 0,
                      zIndex: 991,
                    }}
                  ></span>
                </span>
              </>
            ) : (
              data.price_fee_calc
            )
          }
          text={currencies.krMan}
          circle
          loading={loading}
          error={error}
        />
      </div>
      <Perk value="0" text={`mån bindnings-tid`} light circle />
    </div>
  );
};
